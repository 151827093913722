// Copyright 2021 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as Setting from "../../setting/Setting";
import i18next from "i18next";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Box, Stack } from "@mui/material";

class AuthCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      msg: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.login();
  }

  login() {
    Setting.signin().then(res => {
      if (res.status === "ok") {
        Setting.showMessage(
          "success",
          i18next.t("login:Logged in successfully")
        );
        Setting.goToLink("/");
      } else {
        this.setState({
          msg: res.msg,
        });
      }
    });
  }

  handleBackHomePage() {
    window.location.href = "/";
  }

  render() {
    return (
      <div>
        {this.state.msg === null ?
          // <Spin size="large" tip={i18next.t("login:Signing in...")} style={{paddingTop: "10%", paddingBottom: "10%"}} />
          <Backdrop
            sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" sx={{ mr: 2 }} />
            {i18next.t("login:Signing in...")}
          </Backdrop>
        : <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100vh"
          >
            {/* <Result
              status="error"
              title={i18next.t("login:Signing In Error")}
              subTitle={this.state.msg}
              extra={[
                <Button type="primary" key="details">
                  {i18next.t("login:Details")}
                </Button>,
                <Button key="help">{i18next.t("login:Help")}</Button>,
              ]}
            /> */}
            <Box maxWidth="1060px">
              <Alert severity="error">
                <AlertTitle> {i18next.t("login:Signing In Error")}</AlertTitle>
                {this.state.msg}
              </Alert>
              <Box mt={2}>
                <Stack direction="row" spacing={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.handleBackHomePage()}
                  >
                    {i18next.t("error:Back to Home Page")}
                  </Button>
                  {/* <Button color="primary" variant="contained">
                    {i18next.t("login:Details")}
                  </Button>
                  <Button variant="outlined" color="primary">
                    {i18next.t("login:Help")}
                  </Button> */}
                </Stack>
              </Box>
            </Box>
          </Box>
        }
      </div>
    );
  }
}

export default withRouter(AuthCallback);
