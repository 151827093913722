// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import * as Setting from "../setting/Setting";
import { Link, withRouter } from "react-router-dom";
import i18next from "i18next";
import * as Conf from "../config/Conf";
import HeaderBasicBreadcrumbs from "../components/common/HeaderBreadcrumbs";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import HelpIcon from "@mui/icons-material/Help";
import CampaignIcon from "@mui/icons-material/Campaign";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FlagIcon from "@mui/icons-material/Flag";
import TableChartIcon from "@mui/icons-material/TableChart";
import HubIcon from "@mui/icons-material/Hub";
import TopicIcon from "@mui/icons-material/Topic";
import SettingsIcon from "@mui/icons-material/Settings";
import PasswordIcon from "@mui/icons-material/Password";
import RadarIcon from "@mui/icons-material/Radar";

class AdminHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      form: {},
      manageItems: [
        {
          label: i18next.t("admin:Tab management"),
          value: "tab",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <TableChartIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Node management"),
          value: "node",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <HubIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Plane management"),
          value: "plane",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <RadarIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Topic management"),
          value: "topic",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <TopicIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Poster management"),
          value: "poster",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <CampaignIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Member management"),
          value: "member",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <SupervisorAccountIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Sensitive management"),
          value: "sensitive",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <PasswordIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Translation management"),
          value: "translation",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <GTranslateIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:FrontConf management"),
          value: "frontconf",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <SettingsIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Faq"),
          value: "faq",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <HelpIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Mission"),
          value: "mission",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <FlagIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Advertise"),
          value: "advertise",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <CampaignIcon fontSize="inherit" color="primary" />,
        },
        {
          label: i18next.t("admin:Thanks"),
          value: "thanks",
          // image: Setting.getStatic("/img/settings.png"),
          icon: <FavoriteIcon fontSize="inherit" color="primary" />,
        },
      ],
      message: "",
    };
  }

  componentDidMount() {
    document.title = `${i18next.t("general:Admin")} - ${Setting.getForumName()}`;
  }

  renderManageItemInternal(item) {
    return (
      <div
        style={{
          display: "table",
          padding: "20px 0px 20px 0px",
          width: "100%",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        {item?.image ?
          <img
            src={item?.image}
            border="0"
            align="default"
            width="73"
            alt={item?.value}
          />
        : <Box fontSize={50}>{item?.icon}</Box>}
        <div className="sep10" />
        {item?.label}
      </div>
    );
  }

  renderManageItem(item) {
    if (item.value === "member") {
      return (
        <Grid size={{ xs: 6, sm: 4, md: 3 }}>
          <Paper
            component="a"
            className="grid_item"
            href={Setting.getMyProfileUrl(this.props.account)}
            rel="noreferrer"
            sx={{ width: "100%" }}
          >
            {this.renderManageItemInternal(item)}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid size={{ xs: 6, sm: 4, md: 3 }}>
        <Paper
          component={Link}
          className="grid_item"
          to={`admin/${item?.value}`}
          sx={{ width: "100%" }}
        >
          {this.renderManageItemInternal(item)}
        </Paper>
      </Grid>
    );
  }

  render() {
    if (this.props.account === undefined) {
      if (!Conf.ShowLoadingIndicator) {
        return null;
      }

      return (
        <div className="box">
          <div className="header">
            <Link to="/">{Setting.getForumName()}</Link>
            <span className="chevron">&nbsp;›&nbsp;</span>{" "}
            {i18next.t("loading:Page is loading")}
          </div>
          <div className="cell">
            <span className="gray bigger">
              {i18next.t("loading:Please wait patiently...")}
            </span>
          </div>
        </div>
      );
    }
    if (this.props.account === null || !this.props.account?.isAdmin) {
      this.props.history.push("/");
    }

    return (
      <div className="box">
        {/* <div className="header">
          <Link to="/">{Setting.getForumName()}</Link> <span className="chevron">&nbsp;›&nbsp;</span>
          {i18next.t("admin:Backstage management")}
        </div> */}
        <HeaderBasicBreadcrumbs
          current={i18next.t("admin:Backstage management")}
        />
        <div id="all-items">
          <Grid container spacing={2}>
            {this.state.manageItems.map(item => {
              return this.renderManageItem(item);
            })}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminHomepage);
