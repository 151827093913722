import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import { styled, alpha, useTheme } from "@mui/material/styles";

const FabWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 50,
  right: 100,
  zIndex: 1000,
  [theme.breakpoints.down("md")]: {
    right: 50,
  },
  [theme.breakpoints.down("sm")]: {
    right: 25,
  },
}));

function BackTop(props) {
  const { children, window } = props;
  const theme = useTheme();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <FabWrapper onClick={handleClick} role="presentation">
        <Fab
          size="small"
          aria-label="scroll back to top"
          color="primary"
          // sx={{
          //   backgroundColor: alpha(theme.palette.primary.main, 0.2),
          //   borderColor: alpha(theme.palette.primary.main, 0.8),
          //   borderWidth: 1,
          //   borderStyle: "solid",
          //   "&:hover": {
          //     backgroundColor: alpha(theme.palette.primary.main, 0.8),
          //   },
          // }}
        >
          <KeyboardArrowUpIcon
          // color="primary"
          // sx={{
          //   color: theme.palette.primary.dark,
          // }}
          />
        </Fab>
      </FabWrapper>
    </Fade>
  );
}

export default BackTop;
