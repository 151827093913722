import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import i18next from "i18next";
import SearchComponent from "./Search";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

const SearchDialog = props => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [matchNodes, setMatchNodes] = useState([]);
  //   TODO 抽离
  const getMatchNodes = useCallback(
    curSearchVal => {
      if (!curSearchVal || !props.nodes) return [];
      return props.nodes.filter(
        node =>
          node.name.includes(curSearchVal) || node.id.includes(curSearchVal)
      );
    },
    [props.nodes]
  );
  const handleSearchChange = e => {
    const value = e.target.value;
    setSearchValue(value);
    setMatchNodes(getMatchNodes(value));
  };
  const handleSearch = e => {
    history.push(`/search?keyword=${searchValue}`);
    props.handleClose();
    setSearchValue("");
  };
  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };
  return (
    <React.Fragment>
      <Dialog fullScreen open={props.open} onClose={props.handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {i18next.t("topic:Search")}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={props.handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box mt={2}>
          <FormControl variant="filled" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              {i18next.t("topic:Search")}
            </InputLabel>
            <FilledInput
              value={searchValue}
              maxLength="50"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              label={i18next.t("topic:Search")}
              onChange={handleSearchChange}
              onKeyUp={handleKeyUp}
            />
          </FormControl>
          <SearchComponent matchNodes={matchNodes} searchValue={searchValue} />
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default SearchDialog;
