import React, { useState, useEffect, useCallback } from "react";
import * as AccountBackend from "../../backend/AccountBackend";
import * as NodeBackend from "../../backend/NodeBackend";
import * as Setting from "../../setting/Setting";
import * as Conf from "../../config/Conf";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18next from "i18next";
import { styled, alpha, useTheme, useColorScheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import LanguageIcon from "@mui/icons-material/Language";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Stack } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import SearchDialog from "./SearchDialog";
import SearchComponent from "./Search";

const languageMap = {
  zh: "简体中文",
  "zh-TW": "繁體中文",
  en: "English",
  // fr: "Français",
  // de: "Deutsch",
  // ko: "한국어",
  // ru: "Русский",
  // ja: "日本語",
  // kk: "قازاق ٴتىلى",
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  height: "28px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    fontSize: "14px",
    maxWidth: "12ch",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      maxWidth: "20ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#222",
  color: "#a68540",
  display: "flex",
  alignItems: "center",
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "1060px",
  [theme.breakpoints.up(1100)]: {
    padding: "0 !important",
  },
}));

const Logo = styled("div")(({ theme }) => ({
  backgroundImage: "url(https://cdn.casbin.com/forum/static/img/logo.svg)",
  backgroundRepeat: "no-repeat",
  display: "inline-block",
  [theme.breakpoints.up("md")]: {
    width: "125px",
    height: "34px",
    backgroundSize: "125px 34px",
  },
  [theme.breakpoints.down("md")]: {
    width: "80px",
    height: "25px",
    backgroundSize: "80px 25px",
  },
}));

const Header = ({ account, member, onSignout, getMember }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { mode, setMode } = useColorScheme();

  const confirm = useConfirm();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [searchValue, setSearchValue] = useState("");
  const [nodes, setNodes] = useState([]);
  const [matchNodes, setMatchNodes] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchResShow, setSearchResShow] = useState(false);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") ?
      localStorage.getItem("themeMode")
    : "light"
  );

  const [searchVisible, setSearchVisible] = useState(false);

  const getNodes = useCallback(() => {
    NodeBackend.getNodes().then(res => setNodes(res));
  }, []);

  useEffect(() => {
    getNodes();
  }, [getNodes]);

  useEffect(() => {
    account?.name && getMember(account.name);
  }, [account]);

  const getMatchNodes = useCallback(
    curSearchVal => {
      if (!curSearchVal || !nodes) return [];
      return nodes.filter(
        node =>
          node.name.includes(curSearchVal) || node.id.includes(curSearchVal)
      );
    },
    [nodes]
  );

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearchValue(value);
    setMatchNodes(getMatchNodes(value));
    setSearchResShow(!!value);
  };

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      history.push(`/search?keyword=${searchValue}`);
      setSearchResShow(false);
    }
  };

  const handleFocus = e => {
    setSearchResShow(!!searchValue);
    setAnchorElSearch(e.currentTarget);
  };

  const handleSignout = async () => {
    // if (window.confirm(i18next.t("signout:Are you sure to log out?"))) {
    //   AccountBackend.signout().then(res => {
    //     onSignout?.();
    //     window.location.href = "/";
    //   });
    // }
    const { confirmed } = await confirm({
      description: i18next.t("signout:Are you sure to log out?"),
    });

    if (confirmed) {
      AccountBackend.signout().then(res => {
        onSignout?.();
        window.location.href = "/";
      });
    }
    setAnchorElUser(null);
  };

  const handleChangeLanguage = value => {
    setLanguage(value);
    const searchParams = new URLSearchParams(history.location.search);
    const previousNative = searchParams.get("previous");
    Setting.changeLanguage(value, previousNative ?? "/");
  };

  const handleChangeTheme = () => {
    const newMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);
    setMode(newMode);
    Setting.toggleThemeMode();
  };

  const renderAuthButtons = () => {
    return (
      <Stack
        direction="row"
        spacing={isSmallScreen ? 1 : 2}
        alignItems="center"
        ml={1}
      >
        {/* {location.pathname !== "/" ?
          <Button
            component={Link}
            to="/"
            color="primary"
            size="small"
            sx={{ minWidth: isSmallScreen ? "32px" : "64px" }}
          >
            {i18next.t("general:Home")}
          </Button>
        : null} */}
        {isSmallScreen ?
          <IconButton
            color="primary"
            size={"small"}
            onClick={event => setAnchorElLang(event.currentTarget)}
          >
            <LanguageIcon
              fontSize={"small"}
              // className={classes.terminalOpen}
            />
          </IconButton>
        : <Button
            aria-controls="language-menu"
            aria-haspopup="true"
            color="primary"
            startIcon={<LanguageIcon />}
            variant="text"
            size="small"
            sx={{
              textTransform: "none",
            }}
            onClick={event => setAnchorElLang(event.currentTarget)}
          >
            {languageMap[language]}
          </Button>
        }
        <IconButton color="primary" size={"small"} onClick={handleChangeTheme}>
          {themeMode === "light" ?
            <LightModeIcon fontSize={"small"} />
          : <DarkModeIcon fontSize={"small"} />}
        </IconButton>
        <Menu
          value={language}
          anchorEl={anchorElLang}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorElLang)}
          onClose={() => setAnchorElLang(null)}
        >
          {Object.entries(languageMap).map(i => (
            <MenuItem
              key={i[0]}
              value={i[0]}
              selected={language === i[0]}
              onClick={() => handleChangeLanguage(i[0])}
            >
              {i[1]}
            </MenuItem>
          ))}
        </Menu>
        {!account ?
          <>
            <Button
              href={Setting.getSignupUrl()}
              size="small"
              sx={{ minWidth: isSmallScreen ? "32px" : "64px", flexShrink: 0 }}
            >
              {i18next.t("general:Sign Up")}
            </Button>
            <Button
              href={Setting.getSigninUrl()}
              size="small"
              sx={{ minWidth: isSmallScreen ? "32px" : "64px", flexShrink: 0 }}
            >
              {i18next.t("general:Sign In")}
            </Button>
          </>
        : <>
            <IconButton
              onClick={e => setAnchorElUser(e.currentTarget)}
              color="inherit"
            >
              <Avatar
                alt={account.name}
                sx={{ width: 30, height: 30 }}
                src={
                  member?.avatar ||
                  account?.avatar ||
                  Setting.getUserAvatar(account.name)
                }
              />
            </IconButton>
            <Menu
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              <MenuItem
                component={Link}
                to={`/member/${account.name}`}
                onClick={() => setAnchorElUser(null)}
              >
                {i18next.t("general:Homepage")}
              </MenuItem>
              <MenuItem
                component="a"
                href={Setting.getMyProfileUrl(account)}
                onClick={() => setAnchorElUser(null)}
              >
                {i18next.t("general:Setting")}
              </MenuItem>
              {account?.isAdmin && (
                <MenuItem
                  component={Link}
                  to="/admin"
                  onClick={() => setAnchorElUser(null)}
                >
                  {i18next.t("general:Admin")}
                </MenuItem>
              )}
              <MenuItem onClick={handleSignout}>
                {i18next.t("general:Sign Out")}
              </MenuItem>
            </Menu>
          </>
        }
      </Stack>
    );
  };

  const handleClickOpen = () => {
    setSearchVisible(true);
  };

  const handleClose = () => {
    setSearchVisible(false);
  };

  return (
    <CustomAppBar position="static">
      <CustomToolbar id="back-to-top-anchor" variant="dense">
        <Box display="flex" alignItems="center" mr={1}>
          <Link
            to="/"
            name="top"
            style={{ fontSize: 0 }}
            title={Conf.FrontConfig.signinBoxSpan}
          >
            <Logo
              style={{
                // backgroundImage: `url(${Conf.FrontConfig.logoImage})`
                backgroundImage: `url(/img/pearwrt.png)`,
              }}
            />
          </Link>
          <Box ml={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            {isExSmallScreen ?
              <Box ml={1}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleClickOpen}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
                <SearchDialog
                  open={searchVisible}
                  nodes={nodes}
                  handleClose={handleClose}
                />
              </Box>
            : <Search>
                <SearchIconWrapper>
                  <SearchIcon fontSize="small" />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={i18next.t("topic:Search")}
                  value={searchValue}
                  maxLength="50"
                  autoComplete="off"
                  onChange={handleSearchChange}
                  onKeyUp={handleKeyUp}
                  onFocus={handleFocus}
                  onBlur={() => setTimeout(() => setSearchResShow(false), 200)}
                />
                <Popper
                  sx={{ zIndex: 1200 }}
                  open={searchResShow && !!searchValue}
                  anchorEl={anchorElSearch}
                  placement="bottom-start"
                  transition
                >
                  {({ TransitionProps }) => (
                    // <Fade in={searchResShow && !!searchValue}>
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <div
                          className="box"
                          style={{
                            // position: "block",
                            zIndex: 100,
                          }}
                        >
                          {/* <Typography variant="body2" className="cell" p="10px">
                            {i18next.t("search:Press Enter to search in site.")}
                          </Typography>
                          {matchNodes.length > 0 && (
                            <Box>
                              <List
                                dense
                                subheader={
                                  <ListSubheader sx={{ lineHeight: "36px" }}>
                                    {i18next.t("search:Nodes")}
                                  </ListSubheader>
                                }
                              >
                                {matchNodes.map(node => (
                                  <ListItem disablePadding key={node.id}>
                                    <ListItemButton
                                      component="a"
                                      href={`/go/${node.id}`}
                                      fullWidth
                                    >
                                      <ListItemText
                                        primary={`${node.name} / ${node.id}`}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          )}
                          <Divider />
                          <Button
                            component="a"
                            href={generateSearchUrl()}
                            target="_blank"
                            fullWidth
                          >
                            {i18next.t("search:Click here to search in ", {
                              engine: Conf.DefaultSearchSite,
                            })}
                          </Button> */}
                          <SearchComponent
                            matchNodes={matchNodes}
                            searchValue={searchValue}
                          />
                        </div>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </Search>
            }
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {renderAuthButtons()}
        </Box>
      </CustomToolbar>
    </CustomAppBar>
  );
};

export default Header;
