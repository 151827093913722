// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import * as Setting from "../setting/Setting";
import * as TopicBackend from "../backend/TopicBackend";
import Avatar from "../components/common/Avatar";
import "../assets/css/node.css";
import i18next from "i18next";
import { Link } from "react-router-dom";
import UserLink from "../components/common/UserLink";
// import * as MuiAvatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import * as MuiLink from "@mui/material/Link";

const pangu = require("pangu");

class TopicList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
    };
  }

  addTopicHitCount(topicId) {
    TopicBackend.addTopicHitCount(topicId).then(res => {
      if (res?.status === "fail") {
        Setting.showMessage("error", res?.msg);
      }
      // goToLink(`/t/${topicId}?from=${encodeURIComponent(window.location.href)}`)
    });
  }

  topTopicStyle = {
    backgroundImage: `url('${Setting.getStatic("/img/corner_star.png")}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px 20px",
    backgroundPosition: "right top",
  };

  // return style according to whether it is a topped topic.
  topStyle(nodeTopTime, tabTopTime, homePageTopTime) {
    switch (this.props.topType) {
      case "node":
        if (nodeTopTime !== "") {
          return this.topTopicStyle;
        }
        return null;
      case "tab":
        if (tabTopTime !== "" || homePageTopTime !== "") {
          return this.topTopicStyle;
        }
        return null;
      case "homePage":
        if (homePageTopTime !== "") {
          return this.topTopicStyle;
        }
        return null;
      default:
        return null;
    }
  }

  renderTopic(topic, index) {
    const pcBrowser = Setting.PcBrowser;
    const style = this.topStyle(
      topic?.nodeTopTime,
      topic?.tabTopTime,
      topic?.homePageTopTime
    );

    return (
      <div
        key={topic?.id}
        className={`item ${this.props.nodeId}`}
        style={style}
      >
        {/* <table cellPadding="0" cellSpacing="0" border="0" width="100%"> */}
        {/* <tbody> */}
        <ListItem
          sx={{
            alignItems: "flex-start",
          }}
          secondaryAction={
            // <td width="70" align="right" valign="middle">
            <>
              {/* {topic.replyCount === 0 ? null : (
                <Link
                  to={`/t/${topic?.id}`}
                  onClick={() => this.addTopicHitCount(topic?.id)}
                  
                >
                  {topic.replyCount}
                </Link>
              )} */}
              {topic.replyCount === 0 ? null : (
                <Badge
                  color="primary"
                  component={Link}
                  badgeContent={topic.replyCount}
                  to={`/t/${topic?.id}`}
                  onClick={() => this.addTopicHitCount(topic?.id)}
                ></Badge>
              )}
            </>
            // </td>
          }
        >
          {this.props.showAvatar ?
            // <td
            //   width={Setting.PcBrowser ? "48" : "24"}
            //   valign="top"
            //   align="center"
            // >
            //   <Avatar
            //     username={topic?.author}
            //     avatar={topic?.avatar}
            //     size={Setting.PcBrowser ? "" : "small"}
            //   />
            // </td>
            <ListItemAvatar sx={{ minWidth: pcBrowser ? 56 : 34 }}>
              {/* <Avatar
                alt={topic?.author}
                src={topic?.avatar}
                sx={{ width: pcBrowser ? 48 : 24, height: pcBrowser ? 48 : 24 }}
                variant="rounded"
              /> */}
              <Avatar
                username={topic?.author}
                avatar={topic?.avatar}
                size={Setting.PcBrowser ? "" : "small"}
              />
            </ListItemAvatar>
          : null}
          {/* {this.props.showAvatar ? <td width="10" /> : null} */}
          {/* <td width="auto" valign="middle"> */}
          <ListItemText
            primary={
              <>
                {!pcBrowser && this.props.showNodeName ?
                  // <span className="small fade">
                  //   <Link
                  //     to={`/go/${encodeURIComponent(topic.nodeId)}`}
                  //     className="node"
                  //   >
                  //     {topic.nodeName}
                  //   </Link>{" "}
                  //   &nbsp;•&nbsp;{" "}
                  //   <strong>
                  //     <UserLink username={topic.author} classNameText={"node"} />
                  //   </strong>
                  // </span>
                  <Typography variant="body2" className="small fade">
                    {/* <Link
                      href={`/go/${encodeURIComponent(topic.nodeId)}`}
                      passHref
                    >
                      <a className="node">{topic.nodeName}</a>
                    </Link>{" "} */}
                    <Chip
                      component="a"
                      href={`/go/${encodeURIComponent(topic.nodeId)}`}
                      label={topic.nodeName}
                      size="small"
                      onClick={() => {}}
                    />
                    &nbsp;•&nbsp;{" "}
                    <strong>
                      <UserLink
                        username={topic.author}
                        // classNameText={"node"}
                      />
                    </strong>
                  </Typography>
                : null}
                {/* {!pcBrowser && this.props.showNodeName ? (
            <div className="sep5" />
          ) : null} */}
                {/* <span className="item_title">
            <Link
              to={`/t/${topic?.id}`}
              onClick={() => this.addTopicHitCount(topic?.id)}
              className={`topic-link b ${this.props.nodeId}`}
            >
              {pangu.spacing(topic.title)}
            </Link>
          </span> */}
                <Typography
                  variant="subtitle1"
                  component="span"
                  className="item_title"
                >
                  <Link
                    to={`/t/${topic?.id}`}
                    onClick={() => this.addTopicHitCount(topic?.id)}
                    className={`topic-link b ${this.props.nodeId}`}
                  >
                    {pangu.spacing(topic.title)}
                  </Link>
                </Typography>
              </>
            }
            secondary={
              <span className={pcBrowser ? "topic_info" : "small fade"}>
                {pcBrowser ?
                  <span>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                      useFlexGap
                      sx={{
                        alignItems: "center",
                        height: "32px",
                        padding: "10px 0",
                      }}
                    >
                      {this.props.showNodeName ?
                        <span>
                          <Link
                            to={`/go/${encodeURIComponent(topic.nodeId)}`}
                            // className="node"
                          >
                            {/* {topic.nodeName} */}
                            <Chip
                              label={topic.nodeName}
                              // variant="outlined"
                              size="small"
                              onClick={() => {}}
                            />
                          </Link>
                        </span>
                      : null}
                      <Typography variant="body2">
                        <UserLink
                          username={topic.author}
                          classNameText={`${this.props.nodeId} member`}
                        />
                      </Typography>
                      <Typography variant="body2">
                        {(
                          topic.lastReplyTime === "" ||
                          this.props.timeStandard === "createdTime"
                        ) ?
                          Setting.getPrettyDate(topic.createdTime)
                        : Setting.getPrettyDate(topic.lastReplyTime)}
                      </Typography>
                      {topic.lastReplyUser === "" ? null : (
                        <div style={{ display: "inline" }}>
                          {i18next.t("topic:last reply from")}
                          <strong>
                            <UserLink
                              username={topic.lastReplyUser}
                              classNameText={`${this.props.nodeId} member`}
                            />
                          </strong>
                        </div>
                      )}
                    </Stack>
                  </span>
                : <span>
                    {this.props.showNodeName ?
                      <span>
                        {(
                          topic.lastReplyTime === "" ||
                          this.props.timeStandard === "createdTime"
                        ) ?
                          Setting.getPrettyDate(topic.createdTime)
                        : Setting.getPrettyDate(topic.lastReplyTime)}
                        {topic.lastReplyUser === "" ? null : (
                          <div style={{ display: "inline" }}>
                            {" "}
                            &nbsp;•&nbsp; {i18next.t(
                              "topic:last reply from"
                            )}{" "}
                            <strong>
                              <UserLink
                                username={topic.lastReplyUser}
                                classNameText={`${this.props.nodeId} member`}
                              />
                            </strong>
                          </div>
                        )}
                      </span>
                    : <span>
                        <strong>{topic.author}</strong> &nbsp;•&nbsp;{" "}
                        {topic.contentLength} {i18next.t("topic:words")}{" "}
                        &nbsp;•&nbsp; {topic.hitCount} {i18next.t("topic:hits")}
                      </span>
                    }
                  </span>
                }
              </span>
            }
            sx={{
              m: 0,
            }}
          ></ListItemText>

          {/* </td> */}
        </ListItem>
        <Divider component="li" />
        {/* </tbody> */}
        {/* </table> */}
      </div>
    );
  }

  render() {
    return (
      // <div className={`box ${this.props.nodeId}`}>
      <List>
        {this.props.topics?.map((topic, index) => {
          return this.renderTopic(topic, index);
        })}
      </List>
      // </div>
    );
  }
}

export default TopicList;
