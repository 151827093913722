// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import ReactDOM from "react-dom";
import "./config/i18n.js";
import "./assets/css/index.css";
import App from "./App";
import * as serviceWorker from "./config/serviceWorker.js";
import { BrowserRouter } from "react-router-dom";
import { loadDefaultThemeMode } from "./config/theme/index.js";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import MuiTheme from "./config/theme/config.js";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import i18next from "i18next";
// import "./components/common/css/markdown.css";
import "./components/common/css/markdown2.css";
import "./components/common/css/github-markdown.css";

// const theme = localStorage.getItem("CASNODE_THEME");
// loadTheme(theme);
const defalutTheme = loadDefaultThemeMode();

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <BrowserRouter>
    <CssBaseline />
    <ThemeProvider theme={MuiTheme} defaultMode={defalutTheme}>
      <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: { autoFocus: true },
          title: i18next.t("confirm:Are you sure?"),
          cancellationText: i18next.t("confirm:Cancel"),
          confirmationText: i18next.t("confirm:OK"),
        }}
      >
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={2500}
          dense={true}
        />
        <App />
      </ConfirmProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
