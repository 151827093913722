// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import * as Setting from "../../setting/Setting";
import * as Conf from "../../config/Conf";
import * as BasicBackend from "../../backend/BasicBackend";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import i18next from "i18next";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Stack } from "@mui/material";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      version: "",
      online: 0,
      highest: 0,
    };
  }

  componentDidMount() {
    this.getForumVersion();
    this.getOnlineNum();
  }

  getForumVersion() {
    BasicBackend.getForumVersion().then(res => {
      this.setState({
        version: res.data,
      });
    });
  }

  getOnlineNum() {
    BasicBackend.getOnlineNum().then(res => {
      this.setState({
        online: res?.data,
        highest: res?.data2,
      });
    });
  }

  render() {
    const loadingTime = Math.floor(
      performance.getEntries()[0].responseEnd -
        performance.getEntries()[0].requestStart
    );
    const date = new Date();

    if (!Setting.PcBrowser) {
      return (
        <div id="Bottom">
          <div className="content">
            <div className="inner" style={{ textAlign: "center" }}>
              &copy; {date.getFullYear()} {Setting.getForumName()} ·{" "}
              {loadingTime}ms ·{" "}
              <a
                href={`${Conf.GithubRepo}/commit/${this.state.version}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.version.substring(0, 7)}
              </a>
              <div>
                <strong>
                  <Link to="/about" className="dark">
                    {i18next.t("footer:About")}
                  </Link>
                  &nbsp;·&nbsp;
                  <Link
                    to={{
                      pathname: "/select/language",
                      query: { previous: this.props.location.pathname },
                    }}
                    title="Select Language"
                    className="dark"
                  >
                    Language
                  </Link>
                </strong>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const utcTime = moment().utc(false).format("HH:mm");
    const laxTime = moment().utcOffset(-7).format("HH:mm");
    const pvgTime = moment().format("HH:mm");
    const jfkTime = moment().utcOffset(-4).format("HH:mm");

    return (
      <div id="Bottom">
        <div className="content">
          <Box className="inner" padding="10px 20px">
            <div className="sep10" />
            <div className="fr">
              <a
                href={Conf.FrontConfig.footerLogoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  id="logoFooter"
                  style={{
                    // backgroundImage: `url(${Conf.FrontConfig.footerLogoImage})`,
                    // backgroundImage: `url(/svg/miner.svg)`,
                    backgroundImage: `url(/img/openfog.png)`,
                  }}
                />
              </a>
            </div>
            <Box display="flex" alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  alignItems: "center",
                }}
              >
                <Button
                  component={Link}
                  to="/about"
                  target="_self"
                  size="small"
                >
                  {i18next.t("footer:About")}
                </Button>
                <Button component={Link} to="/faq" target="_self" size="small">
                  FAQ
                </Button>
                <Button
                  component="a"
                  href={`${Setting.ServerUrl}/swagger`}
                  target="_self"
                  size="small"
                >
                  API
                </Button>
                <Button
                  component={Link}
                  to="/mission"
                  target="_self"
                  size="small"
                >
                  {i18next.t("footer:Mission")}
                </Button>
                <Button
                  component={Link}
                  to="/advertise"
                  target="_self"
                  size="small"
                >
                  {i18next.t("footer:Advertise")}
                </Button>
                <Button
                  component={Link}
                  to="/thanks"
                  target="_self"
                  size="small"
                >
                  {i18next.t("footer:Thanks")}
                </Button>
                <Button
                  component={Link}
                  to="/tools"
                  target="_self"
                  size="small"
                >
                  {i18next.t("footer:Tools")}
                </Button>
                <Button
                  component={Link}
                  to="/select/editorType"
                  className="f11"
                  startIcon={<EditIcon fontSize="small" />}
                  size="small"
                >
                  {i18next.t("footer:Select Editor")}
                </Button>
                <Box>
                  {this.state.online} {i18next.t("footer:Online")}
                  &nbsp; <span className="snow">·</span> &nbsp;{" "}
                  <span className="fade">
                    {i18next.t("footer:Highest")} {this.state.highest}
                  </span>{" "}
                </Box>
              </Stack>
            </Box>
            {/*
            <Link
              to={{
                pathname: "/select/language",
                query: { previous: this.props.location.pathname },
              }}
              className="f11"
            >
              <img
                src={Setting.getStatic("/img/language.png")}
                width="16"
                align="absmiddle"
                id="ico-select-language"
              />{" "}
              &nbsp; {i18next.t("footer:Select Language")}
            </Link> */}
            <div className="sep20" />
            {i18next.t("footer:Community of Creators")}
            <div className="sep5" />
            {Conf.FrontConfig.footerDeclaration}
            <div className="sep20" />
            <span className="small fade">
              VERSION:{" "}
              <a
                href={`${Conf.GithubRepo}/commit/${this.state.version}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.version.substring(0, 7)}
              </a>{" "}
              · {loadingTime}ms · UTC {utcTime} · PVG {pvgTime} · LAX {laxTime}{" "}
              · JFK {jfkTime}
              <br />
              {Conf.FrontConfig.footerAdvise}
            </span>
            <div className="sep10" />
          </Box>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
