// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import * as Setting from "../setting/Setting";
import Avatar from "../components/common/Avatar";
import * as FavoritesBackend from "../backend/FavoritesBackend";
import * as NotificationBackend from "../backend/NotificationBackend";
import { Link } from "react-router-dom";
import "../assets/css/node.css";
import i18next from "i18next";
import { scoreConverter } from "../main/Tools";
import * as Conf from "../config/Conf";
import Paper from "@mui/material/Paper";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { ThemeSwitch } from "../components/muiBase/ThemeSwitch";
import { Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CollectionsIcon from "@mui/icons-material/Collections";
import EditNoteIcon from "@mui/icons-material/EditNote";

class RightAccountBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      topicFavoriteNum: 0,
      nodeFavoriteNum: 0,
      followingNum: 0,
      topicSubscribeNum: 0,
      replySubscribeNum: 0,
      unreadNotificationNum: 0,
      themeMode: undefined,
    };
  }

  componentDidMount() {
    // this.getFavoriteNum();
    this.getUnreadNotificationNum();
    if (Conf.EnableNotificationAutoUpdate) {
      setInterval(() => {
        this.getUnreadNotificationNum();
      }, Conf.NotificationAutoUpdatePeriod * 1000);
    }
  }

  getUnreadNotificationNum() {
    NotificationBackend.getUnreadNotificationNum().then(res => {
      this.setState({
        unreadNotificationNum: res?.data,
      });
    });
  }

  readAllNotifications() {
    NotificationBackend.updateReadStatus().then(res => {
      if (res) {
        this.setState({
          unreadNotificationNum: 0,
        });
      }
    });
  }

  getFavoriteNum() {
    FavoritesBackend.getAccountFavoriteNum().then(res => {
      if (res.status === "ok") {
        this.setState({
          topicFavoriteNum: res?.data[1],
          followingNum: res?.data[2],
          nodeFavoriteNum: res?.data[3],
          topicSubscribeNum: res?.data[4],
          replySubscribeNum: res?.data[5],
        });
      } else {
        Setting.showMessage("error", res.msg);
      }
    });
  }

  render() {
    const username = this.props.account?.name;
    const avatar = this.props.member?.avatar || this.props.account?.avatar;
    const tagline = this.props.account?.tagline;
    const favorites = this.props.favorites;
    const { goldCount, silverCount, bronzeCount } = scoreConverter(
      this.props.account.score
    );

    return (
      // <div className={`box ${this.props.nodeId}`}>
      <Paper sx={{ mb: "20px" }}>
        <div className={`cell ${this.props.nodeId}`}>
          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
            <tbody>
              <tr>
                <td width="48" valign="top">
                  <Avatar username={username} avatar={avatar} />
                </td>
                <td width="10" valign="top" />
                <td width="auto" align="left">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>
                      <span className="bigger">
                        <Link
                          to={`/member/${username}`}
                          className={`${this.props.nodeId}`}
                        >
                          {username}
                        </Link>
                      </span>
                      <div className="sep5"></div>
                      <span className="fade">{tagline}</span>
                    </div>
                    <div
                      className="fr light-toggle"
                      style={{ cursor: "pointer" }}
                      onClick={Setting.toggleThemeMode}
                    >
                      {/* <img
                      src={Setting.getThemeBtnUrl()}
                      align="absmiddle"
                      height="10"
                      alt="Light"
                    /> */}
                      {/* <ThemeSwitch
                        checked={localStorage.getItem("themeMode") === "dark"}
                      /> */}
                    </div>
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="sep10" />
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Box width="33%" align="center">
              <Link
                to="/my/nodes"
                className="dark"
                style={{ display: "block" }}
              >
                {/* <span className="bigger"> */}
                <Typography color="textPrimary">
                  {favorites === undefined ?
                    this.state.nodeFavoriteNum
                  : favorites[3]}
                </Typography>
                {/* </span> */}
                <div className="sep3" />
                {/* <span className="fade">{i18next.t("bar:Nodes")}</span> */}
                <Typography variant="subtitle2" color="textSecondary">
                  {i18next.t("bar:Nodes")}
                </Typography>
              </Link>
            </Box>
            <Box width="34%" align="center">
              <Link
                to="/my/topics"
                className="dark"
                style={{ display: "block" }}
              >
                {/* <span className="bigger"> */}
                <Typography color="textPrimary">
                  {favorites === undefined ?
                    this.state.topicFavoriteNum + this.state.topicSubscribeNum
                  : favorites[1] + favorites[4]}
                </Typography>
                {/* </span> */}
                <div className="sep3" />
                <Typography variant="subtitle2" color="textSecondary">
                  {i18next.t("bar:Topics")}
                </Typography>
              </Link>
            </Box>
            <Box width="33%" align="center">
              <Link
                to="/my/following"
                className="dark"
                style={{ display: "block" }}
              >
                <Typography color="textPrimary">
                  {favorites === undefined ?
                    this.state.followingNum
                  : favorites[2]}
                </Typography>
                <div className="sep3" />
                <Typography variant="subtitle2" color="textSecondary">
                  {i18next.t("bar:Watch")}
                </Typography>
              </Link>
            </Box>
          </Stack>
        </div>
        {/* <div className={`cell ${this.props.nodeId}`} id="member-activity">
          <div className="member-activity-bar">
          <div className="member-activity-start" style={{ width: "20px" }} />
          </div>
        </div> */}
        {/* <div
          className={`cell ${this.props.nodeId}`}
          style={{ padding: "8px", lineHeight: "100%" }}
        >
          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
            <tbody>
              <tr>
                <td width="28">
                  <Link to="/i">
                    <img
                      src={Setting.getStatic("/img/essentials/images.png")}
                      width="28"
                      border="0"
                      style={{ verticalAlign: "bottom" }}
                    />
                  </Link>
                </td>
                <td width="10"></td>
                <td width="auto" valign="middle" align="left">
                  <a
                    target="_blank"
                    href={Setting.getMyResourcesUrl(this.props.account)}
                    rel="noreferrer"
                  >
                    {i18next.t("bar:File library")}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`cell ${this.props.nodeId}`}
          style={{ padding: "8px", lineHeight: "100%" }}
        >
          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
            <tbody>
              <tr>
                <td width="28">
                  <Link to="/new">
                    <img
                      src={Setting.getStatic("/img/essentials/compose.png")}
                      width="28"
                      border="0"
                      style={{ verticalAlign: "bottom" }}
                    />
                  </Link>
                </td>
                <td width="10" />
                <td width="auto" valign="middle" align="left">
                  <Link to="/new" className={`${this.props.nodeId}`}>
                    {i18next.t("bar:Compose")}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
        <List dense>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/i">
              <ListItemIcon
                sx={{
                  minWidth: "36px",
                }}
              >
                <CollectionsIcon fontSize="small" color="info" />
              </ListItemIcon>
              <ListItemText primary={i18next.t("bar:File library")} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton
              component="a"
              href={Setting.getMyResourcesUrl(this.props.account)}
            >
              <ListItemIcon
                sx={{
                  minWidth: "36px",
                }}
              >
                <CollectionsIcon fontSize="small" color="info" />
              </ListItemIcon>
              <ListItemText primary={"文件管理"} />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/new">
              <ListItemIcon
                sx={{
                  minWidth: "36px",
                }}
              >
                <EditNoteIcon fontSize="small" color="info" />
              </ListItemIcon>
              <ListItemText primary={i18next.t("bar:Compose")} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <div className="inner">
          <div className="fr" id="money" style={{ margin: "-3px 0px 0px 0px" }}>
            <Link to="/balance" className="balance_area">
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  alignItems: "center",
                }}
              >
                {goldCount !== 0 ?
                  <Box display="flex" alignItems="center">
                    <Box height="16px" lineHeight="19px" mr="2px">
                      {goldCount}
                    </Box>
                    <img
                      src={Setting.getStatic("/img/gold@2x.png")}
                      height="16"
                      alt="G"
                      border="0"
                    />
                  </Box>
                : null}
                <Box display="flex" alignItems="center">
                  <Box height="16px" lineHeight="19px" mr="2px">
                    {silverCount}
                  </Box>
                  <img
                    src={Setting.getStatic("/img/silver@2x.png")}
                    height="16"
                    alt="S"
                    border="0"
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <Box height="16px" lineHeight="19px" mr="2px">
                    {bronzeCount}
                  </Box>
                  <img
                    src={Setting.getStatic("/img/bronze@2x.png")}
                    height="16"
                    alt="B"
                    border="0"
                  />
                </Box>
              </Stack>
            </Link>
          </div>
          {this.state.unreadNotificationNum !== 0 ?
            <span>
              <img
                src={Setting.getStatic("/img/dot_orange.png")}
                align="absmiddle"
              />{" "}
            </span>
          : null}
          <Typography color="textSecondary" variant="subtitle2">
            {this.state.unreadNotificationNum === 0 ?
              <Link to="/notifications" className={`fade ${this.props.nodeId}`}>
                0 {i18next.t("bar:unread")}
              </Link>
            : <strong>
                <Link
                  onClick={() => this.readAllNotifications()}
                  to="/notifications"
                  className={`fade ${this.props.nodeId}`}
                >
                  {this.state.unreadNotificationNum} {i18next.t("bar:unread")}
                </Link>
              </strong>
            }
          </Typography>
        </div>
        {/* </div> */}
      </Paper>
    );
  }
}

export default RightAccountBox;
