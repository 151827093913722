import { createTheme } from "@mui/material/styles";
// import {
//   zhCN,
//   zhTW,
//   enUS,
//   frFR,
//   deDE,
//   koKR,
//   ruRU,
//   jaJP,
//   kkKZ,
// } from "@mui/material/locale";
import * as locales from "@mui/material/locale";

const languageMap = {
  zh: "zhCN",
  "zh-TW": "zhTW",
  en: "enUS",
  fr: "frFR",
  de: "deDE",
  ko: "koKR",
  ru: "ruRU",
  ja: "jaJP",
  kk: "kkKZ",
};

const lang = languageMap[localStorage.getItem("language")];

const MuiTheme = createTheme(
  {
    colorSchemes: {
      light: true,
      dark: {
        palette: {
          primary: {
            main: "#9b7f22",
          },
          secondary: {
            main: "#222",
          },
        },
      },
    },
    palette: {
      primary: {
        //漂亮金
        light: "#ffe080",
        main: "#cfae51",
        dark: "#9b7f22",
        contrastText: "#000",
      },
      // secondary: {//目前的按钮色
      //     light: '#dab46d',
      //     main: '#cfae51',
      //     dark: '#9a7a15',
      //     contrastText: '#000',
      // },
      secondary: {
        light: "#494949",
        main: "#222",
        dark: "#000",
        contrastText: "#fff",
      },
    },
    breakpoints: {
      values: {
        xs: 320,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  },
  locales[lang]
);

export default MuiTheme;
