// Copyright 2021 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// TODO 使用环境变量切换
// // dev AuthConfig
// export const AuthConfig = {
//   serverUrl: "http://localhost:7001",
//   clientId: "d7b3600e7a09f05c5270",
//   organizationName: "casbinforum",
//   appName: "forum",
//   redirectPath: "/callback",
// };

// 生产环境 prod AuthConfig
export const AuthConfig = {
  serverUrl: "http://43.154.123.18:8000",
  clientId: "9f8f1cab65a88eb3dee9",
  organizationName: "casnode-forum",
  appName: "forum",
  redirectPath: "/callback",
};

// // dev AuthConfig
// export const OAuth2Config = {
//   providerName: "pearlogin",
//   customAuthUrl: "https://third.pear.link/login",
//   customSignupUrl: "https://third.pear.link/signup",
//   clientId: "test",
//   scopes: "test",
// };

// 生产环境 prod AuthConfig
export const OAuth2Config = {
  // 提供商（我司）name，也可调用接口获取，这里写死
  providerName: "pearlogin",
  customAuthUrl: "https://third.pear.link/login",
  customSignupUrl: "https://third.pear.link/signup",
  clientId: "casnode",
  scopes: "casnode",
};

export const FrontConfig = {
  forumName: "PearWrt",
  logoImage: "",
  footerLogoImage: "",
  footerLogoUrl: "",
  signinBoxStrong: "",
  signinBoxSpan: "",
  footerDeclaration: "",
  footerAdvise: "",
};

export const ShowEmbedButtons = false;

export const ShowGithubCorner = false;
export const GithubRepo = "https://github.com/casbin/casnode";

export const Domain = "forum.casbin.com";

export const ForceLanguage = "";
export const DefaultLanguage = "en";

// Support: richtext | markdown
export const DefaultEditorType = "markdown";

// attachment file can be no larger than 20MB by default
export const AttachmentSizeLimitInMb = 20;

// Default search engine
// Support: baidu(www.baidu.com) | google(www.google.com) | cn-bing(cn.bing.com)
export const DefaultSearchSite = "google";

export const EnableNotificationAutoUpdate = false;

export const NotificationAutoUpdatePeriod = 10; // second

export const DefaultTopicPageReplyNum = 100;

export const ReplyMaxDepth = 10;
export const ReplyMobileMaxDepth = 3;

export const AvatarAnonymousUrl =
  "https://cdn.casbin.com/avatar-pool/anonymous.png";
export const AvatarErrorUrl = "https://cdn.casbin.com/avatar-pool/error.png";

export const ShowLoadingIndicator = false;
