import React, { useMemo, useEffect, createElement } from "react";
import { Marked } from "marked";
import markedAlert from "marked-alert";
import { markedHighlight } from "marked-highlight";
import hljs from "highlight.js";
import markedKatex from "marked-katex-extension";
import markedFootnote from "marked-footnote";
import { createDirectives, presetDirectiveConfigs } from "marked-directive";
import { containerConfig, youtubeDirective } from "./extend/containerConfig.js";
import superscript from "./extend/superscript.js";
import subscript from "./extend/subscript.js";
// import markedCodePreview from "marked-code-preview";
import markedMoreLists from "marked-more-lists";
import extendedTables from "marked-extended-tables/src/index.js";
import Zmage from "react-zmage";
import { renderToString } from "react-dom/server";
// import MarkedReact from "marked-react";

const defProp = {
  /**
   * 基础数据
   **/
  // 图片地址
  src: "",
  // 图片标题
  alt: "",
  // 图片描述
  txt: "",
  // 图片集合
  set: [],
  // 图片默认页
  defaultPage: 0,

  /**
   * 预设
   **/
  preset: "",

  /**
   * 功能控制
   **/
  // 控制器 (从 preset 初始化)
  controller: {},
  // 快捷键 (从 preset 初始化)
  hotKey: {},
  // 动画  (从 preset 初始化)
  animate: {},

  /**
   * 界面与交互
   **/
  // 滚动时隐藏 (仅桌面端可用)
  hideOnScroll: true,
  // 封面可见性 (仅桌面端可用)
  coverVisible: false,
  // 背景色
  backdrop: "#FFFFFF",
  // 高度
  zIndex: 1000,
  // 圆角
  radius: 0,
  // 边距
  edge: 0,
  // 是否循环查看
  loop: true,

  /**
   * 生命周期
   **/
  onBrowsing: () => {},
  onZooming: () => {},
  onSwitching: () => {},
  onRotating: () => {},
};

// React Markdown 组件
const Markdown = ({ content }) => {
  const renderer = {
    image({ type, raw, href, title, text }) {
      const zmageComponent = (
        <Zmage src={href} alt={text} className="zmage-container" />
      );
      return renderToString(zmageComponent);
    },
  };
  const html = useMemo(() => {
    const marked = new Marked(
      {
        gfm: true, // 启用 GitHub Flavored Markdown
        breaks: true, // 换行符解析为 <br>
        xhtml: true, // 使用自闭合标签
        // extensions: [getContainerConfig()],
        extensions: [superscript, subscript],
      },
      markedHighlight({
        emptyLangClass: "hljs",
        langPrefix: "hljs language-",
        highlight(code, lang, info) {
          const language = hljs.getLanguage(lang) ? lang : "plaintext";
          return hljs.highlight(code, { language }).value;
        },
      })
    );

    marked
      .use(markedAlert())
      .use(
        markedKatex({
          throwOnError: false,
        })
      )
      .use(
        createDirectives([
          ...presetDirectiveConfigs,
          containerConfig,
          youtubeDirective,
          //   { level: "container", marker: ":::" },
          //   { level: "container", marker: "\\+\\+\\+" },
        ])
      )
      .use(markedFootnote())
      //   .use(markedCodePreview())
      .use(markedMoreLists())
      .use(extendedTables())
      .use({ renderer });

    return marked.parse(content || "");
    // return marked;
  }, [content]);

  useEffect(() => {
    document.querySelectorAll(".zmage-container").forEach(element => {
      if (!element.getAttribute("zmage") && element.getAttribute("src")) {
        element.style.cursor = "zoom-in";
        element.setAttribute("zmage", Date.now());
        element.addEventListener("click", () =>
          Zmage.browsing({
            ...defProp,
            coverRef: { current: element },
            src: element.getAttribute("src"),
          })
        );
      }
    });
  }, [html]);

  return (
    <article
      className="markdown-body"
      dangerouslySetInnerHTML={{ __html: html }}
    />
    // <MarkedReact value={content} instance={html} renderer={renderer} />
  );
};

export default Markdown;
