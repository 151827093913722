// withConfirm.js
import React from "react";
import { useConfirm } from "material-ui-confirm";

const withConfirm = WrappedComponent => {
  return props => {
    const confirm = useConfirm();
    return <WrappedComponent {...props} confirm={confirm} />;
  };
};

export default withConfirm;
