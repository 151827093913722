import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  //   "& .MuiTabs-indicator": {
  //     display: "flex",
  //     justifyContent: "center",
  //     backgroundColor: "transparent",
  //   },
  //   "& .MuiTabs-indicatorSpan": {
  //     maxWidth: 40,
  //     width: "100%",
  //     backgroundColor: "#635ee7",
  //   },
  //   backgroundColor: "#494949",
});

export const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    //   textTransform: "none",
    //   fontWeight: theme.typography.fontWeightRegular,
    //   fontSize: theme.typography.pxToRem(15),
    //   marginRight: theme.spacing(1),
    // color: theme.palette.grey[400],
    // "&.Mui-selected": {
    //   color: "#fff",
    // },
    //   "&.Mui-focusVisible": {
    //     backgroundColor: "rgba(100, 95, 228, 0.32)",
    //   },
  })
);
