// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import * as BalanceBackend from "../backend/BalanceBackend";
import * as Setting from "../setting/Setting";
import { Link } from "react-router-dom";
import "./rightFavourite.css";
import i18next from "i18next";
import { Paper } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

class RightCheckinBonusBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      info: null,
    };
  }

  componentDidMount() {
    this.getCheckinBonusStatus();
  }

  getCheckinBonusStatus() {
    BalanceBackend.getCheckinBonusStatus().then(res => {
      this.setState({
        info: res.data,
      });
    });
  }

  render() {
    if (
      this.state.info === null ||
      this.state.info ||
      this.props.account === null ||
      this.props.account === undefined
    ) {
      return null;
    }

    return (
      <span>
        {/* {Setting.PcBrowser ?
          <div className="sep20" />
        : null} */}
        {/* <div className={`box ${this.props.nodeId}`}> */}
        <Paper sx={{ mb: "20px" }}>
          <List dense>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/mission/daily">
                <ListItemIcon
                  sx={{
                    minWidth: "36px",
                  }}
                >
                  <CardGiftcardIcon fontSize="small" color="warning" />
                </ListItemIcon>
                <ListItemText
                  primary={i18next.t("bar:Receive today's checkin bonus")}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
        {/* </div> */}
      </span>
    );
  }
}

export default RightCheckinBonusBox;
