// Copyright 2020 The casbin Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import * as Setting from "../setting/Setting";

export function getMember(id) {
  return fetch(`${Setting.ServerUrl}/api/get-member?id=${id}`, {
    method: "GET",
    credentials: "include",
  }).then(res => res.json());
}

export function updateMemberLanguage(language) {
  return fetch(
    `${Setting.ServerUrl}/api/update-member-language?language=${language}`,
    {
      method: "POST",
      credentials: "include",
    }
  ).then(res => res.json());
}

export function updateMemberEditorType(editorType) {
  return fetch(
    `${Setting.ServerUrl}/api/update-member-editor-type?editorType=${editorType}`,
    {
      method: "POST",
      credentials: "include",
    }
  ).then(res => res.json());
}

export function getMemberEditorType() {
  return fetch(`${Setting.ServerUrl}/api/get-member-editor-type`, {
    method: "GET",
    credentials: "include",
  }).then(res => res.json());
}

export function getRankingRichList() {
  return fetch(`${Setting.ServerUrl}/api/get-ranking-rich`, {
    method: "GET",
    credentials: "include",
  }).then(res => res.json());
}

export function getRankingPlayerList() {
  return fetch(`${Setting.ServerUrl}/api/get-ranking-player`, {
    method: "GET",
    credentials: "include",
  }).then(res => res.json());
}
