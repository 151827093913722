import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import * as Conf from "../../config/Conf";

import i18next from "i18next";

const Search = props => {
  const generateSearchUrl = () => {
    const encodedQuery = encodeURIComponent(
      `${Conf.Domain}/t ${props.searchValue}`
    );
    switch (Conf.DefaultSearchSite.toLowerCase()) {
      case "google":
        return `https://www.google.com/search?q=site:${encodedQuery}`;
      case "bing":
        return `https://cn.bing.com/search?q=site:${encodedQuery}`;
      case "baidu":
        return `https://www.baidu.com/s?q6=${Conf.Domain}&q3=${props.searchValue}`;
      default:
        return `/search?keyword=${props.searchValue}`;
    }
  };
  return (
    <>
      {props.searchValue ?
        <Typography variant="body2" className="cell" p="10px">
          {i18next.t("search:Press Enter to search in site.")}
        </Typography>
      : null}
      {props.matchNodes?.length > 0 && (
        <Box>
          <List
            dense
            subheader={
              <ListSubheader sx={{ lineHeight: "36px" }}>
                {i18next.t("search:Nodes")}
              </ListSubheader>
            }
          >
            {props.matchNodes.map(node => (
              <ListItem disablePadding key={node.id}>
                <ListItemButton component="a" href={`/go/${node.id}`} fullWidth>
                  <ListItemText primary={`${node.name} / ${node.id}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <Divider />
      {props.searchValue ?
        <Button
          component="a"
          href={generateSearchUrl()}
          target="_blank"
          fullWidth
        >
          {i18next.t("search:Click here to search in ", {
            engine: Conf.DefaultSearchSite,
          })}
        </Button>
      : null}
    </>
  );
};

export default Search;
