/**
 * Downloads a file to the user's computer using a file object by directly linking to the file URL.
 *
 * @param {object} fileObject An object containing file information:
 *   - fileName: The name of the file to be downloaded (required).
 *   - fileUrl: The URL of the file (required).
 */
export const fileDownload = fileObject => {
  const { fileName, fileUrl } = fileObject;

  if (!fileName || !fileUrl) {
    console.error(
      "fileDownload: fileName and fileUrl are required in the fileObject."
    );
    return; // Exit if required information is missing
  }

  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = fileName; // Use the download attribute
  a.target = "_blank"; // Open in a new tab (safer)
  a.style.display = "none"; // Hide the link

  document.body.appendChild(a);
  a.click(); // Simulate a click

  document.body.removeChild(a); // Clean up after the click
};
