import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import * as Setting from "../../setting/Setting";
import { Box } from "@mui/material";

export const BasicBreadcrumbs = props => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" color="inherit" />}
      aria-label="breadcrumb"
      sx={{
        color: props.color?.[1],
      }}
    >
      <MuiLink
        component={Link}
        underline="hover"
        color="inherit"
        to="/"
        sx={{
          color: props.color?.[0],
        }}
      >
        {Setting.getForumName()}
      </MuiLink>
      {props.path?.map(i => (
        <MuiLink
          key={i.to}
          component={Link}
          underline="hover"
          color="inherit"
          to={i.to}
          sx={{
            color: props.color?.[0],
          }}
        >
          {i.name}
        </MuiLink>
      ))}
      <MuiLink underline="hover" color="inherit">
        <Typography color={props.color?.[1] || "textPrimary"}>
          {props.current}
        </Typography>
      </MuiLink>
    </Breadcrumbs>
  );
};

const HeaderBasicBreadcrumbs = props => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="48px"
      p="10px"
      fontSize="14px"
      sx={{
        borderBottom: "2px solid var(--box-border-color)",
      }}
    >
      <BasicBreadcrumbs {...props} />
      {props.headerRight ? props.headerRight : null}
    </Box>
  );
};

export default HeaderBasicBreadcrumbs;
